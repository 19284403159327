import React from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import uspSection from "../components/uspSection"
import BlockContent from '../components/block-content'
import { FaPrint, FaUserShield, FaRegClock, FaShieldAlt } from "react-icons/fa"
import BackgroundImage from 'gatsby-background-image'
import Form from "../components/form"
import UspSection from "../components/uspSection"
import Helmet from 'react-helmet'
import $ from 'jquery'
import PortableText from '@sanity/block-content-to-react'
import XtraMileLogo from '../images/logo.svg'



export const query = graphql`
    query rebrandPageQuery($slug: String) {
        sanityPages(slug: {current: {eq: $slug}}) {
            pagetitle
            pagetype{
                pagetype
            }
            slug {
                current
            }
            usp1{
                uspTitle
                uspText
                icon
            }
            usp2{
                uspTitle
                uspText
                icon
            }
            usp3{
                uspTitle
                uspText
                icon
            }
            _rawFirstcopy
            _rawServices
            _rawSecondcopy
            coupon {
                title
                type
                disclaimer
            }
            headerimage {
                asset {
                    fluid(maxWidth: 1920) {
                        ...GatsbySanityImageFluid
                    }
                }
            }
            serviceimage {
                asset {
                    fluid(maxWidth: 1920) {
                      ...GatsbySanityImageFluid
                    }
                }
            }
    }
        sanityCompanyInfo {
            companyname
            primarycolor{
                hex
            }
            secondarycolor{
                hex
            }
            accentcolor{
                hex
            }
        }
    }
`


const now = new Date();
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const today = days[now.getDay()];

export default ({ data }) => (
    <Layout>
    <Helmet>
    <title>{data.sanityCompanyInfo.companyname} | {data.sanityPages.pagetitle}</title>
      
    </Helmet>
    <div className="popupForm"><Form /></div>
    <div className="rebrand-wrap">
    <BackgroundImage
      style={{
        height: "100%",
        backgroundPosition: "center center"
      }}
      fluid={data.sanityPages.headerimage.asset.fluid}>

      <div className="pageHeader rebrand">
        <div className="innerLeft">
          <div className="pgHeaderBackground" style={{
              backgroundColor: "#191D63",
              opacity: "0.9"
          }}></div>
            
          <p className="coupon">FPL Home Services is <br/> now Xtra Mile!</p>
          <p className="couponType">New company name, same great technicians!</p>
          <p className="disclaimer">*We are now part of the Home Serve family and no longer affiliated with FPL Energy Services.</p>
          <div className="schedulebtn-container">
          </div>
        </div>
      </div>
    </BackgroundImage>
    
    <div className="container pageContent rebrand">
    
      <div className="row row1">
        <PortableText blocks={data.sanityPages._rawFirstcopy} />
      </div>
    </div>

    <div className="container pageContent rebrand">
        
      <div className="row">
        <BlockContent blocks={data.sanityPages._rawSecondcopy} />
      </div>
      <div className="coupon-disclaimer">
                <p><i>{data.sanityPages.coupon.disclaimer}</i></p> 
            </div>
    </div>
    </div>
    
  </Layout>
)